import { Controller } from "@hotwired/stimulus"
import { init } from "pell"
import Turndown from "turndown"

// "pell" controller - Rich Text Editor which is configured to send messages to Telegram Bot
// All rules are made with documentation in mind (#addBoldRule): https://core.telegram.org/bots/api#markdownv2-style

// Connects to data-controller="pell"
export default class extends Controller {
  static targets = [
    "field",
    "editor"
  ]

  connect() {
    this.#escapeSpecialSymbols()
    this.#changeFocus()
    // Need to translate HTML to MarkdownV2
    this.markdownConverter = new Turndown()
    this.#addBoldRule()
    init({
      element: this.editorTarget,
      actions: [
        "bold",
        "italic",
        "link",
        "quote"
      ],
      onChange: (html) => {
        this.fieldTarget.value = this.markdownConverter.turndown(html)
      }
    })
  }

  #addBoldRule() {
    this.markdownConverter.addRule("bold", {
      filter: ["b"],
      replacement: (content) => "*" + content + "*"
    })
  }

  // If there is an error when validating "f.text_area", make the focus on "pell"
  #changeFocus() {
    this.fieldTarget.addEventListener("focus", () => {
      this.fieldTarget.blur()
      this.element.querySelector(".pell-content").focus()
    })
  }

  // https://core.telegram.org/bots/api#formatting-options
  // In all other places characters '_', '*', '[', ']', '(', ')', '~', '`', '>', '#', '+', '-', '=', '|', '{', '}', '.', '!'
  // must be escaped with the preceding character '\'.
  #escapeSpecialSymbols() {
    Turndown.prototype.escape = (content) => {
      const specialSymbols = ["\\[", "\\]", "\\(", "\\)", "\\~", "\\`", "\\>", "\\#", "\\+", "\\-", "\\=", "\\|", "\\{", "\\}", "\\.", "\\!"]
      const specialSymbolsRegex = new RegExp(specialSymbols.join("|"), "g")
      return content.replace(specialSymbolsRegex, (match) => {
        return "\\" + match
      })
    }
  }
}
